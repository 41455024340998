<template>
<div class="card" >
  <div class="card-header" v-if="header">{{ header }}</div>
  <div class="card-body">
    <h5 v-if="title">{{ title }}</h5>
    <slot></slot>
  </div>
  <div class="card-footer" v-if="footer">
  </div>
</div>
</template>
<script>
export default {
  name: "Card",
  props:{
    header: String,
    title: String,
    footer: String
  },
}
</script>