<template>
  <div class="admin-game">
    <h5>
      彩票批次
      <router-link class="btn btn-primary btn-sm float-right" :to="{path:'/admin/game/add'}">添加批次</router-link>
    </h5>
    <div class="mt-3 row">
      <div class="col-sm-3" v-for="item in list" :key="item.index">
        <Card :header="item.title">
          <img :src="item.image" alt="" class="w-100">
          <p class="card-text">
            code:{{ item.code }}<br/>
            添加日期：{{ item.created_at }}<br/>
            数量：{{ item.stock }}<br/>
          </p>
        </Card>
      </div>
    </div>
  </div>
</template>
<script>
import Card from "@/components/Card"
export default {
  components: {
    Card
  },
  data(){
    return {
      list: []
    }
  },
  mounted() {
    this.init()
  },
  methods:{
    init() {
      this.list = [
        {id:'J063',title:'庚子鼠',code:'J063', stock:10000, created_at:'2022-03-16 12:00:00',image:'http://dev.fc.iaplt.net/gzs.jpg'},
        {id:'J065',title:'橙意满满',code:'J065', stock:10000, created_at:'2022-03-16 12:00:00',image:'http://dev.fc.iaplt.net/cymm.jpg'},
      ]
    }
  }
}
</script>